import React from 'react';
import { TCell, TRow } from '../styles/index.styled';
import TableCell from './TableCell';

const TableBody = ({ columns }) => {
  if (!columns) return null;

  return (
    <tbody>
      <TRow numOfColumns={columns?.length} className="table-row">
        {columns.map(
          ({ id, variant, heading, subhead, iconType, alignLeft }) => (
            <TCell
              className={alignLeft ? 'align-left' : ''}
              key={id}
              aria-label={heading}
            >
              <TableCell
                heading={heading}
                iconType={iconType}
                subhead={subhead}
                variant={variant}
              />
            </TCell>
          )
        )}
      </TRow>
    </tbody>
  );
};

export default TableBody;
