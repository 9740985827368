import React from 'react';
import { colors } from '../../../styles/atoms/colors';
import Icon from '../../../styles/atoms/icons';

const TableCell = ({ variant, heading, subhead, iconType }) => (
  <>
    {variant === 'text' && (
      <>
        {heading && <span className="heading">{heading}</span>}
        {subhead && (
          <div
            className="subhead"
            dangerouslySetInnerHTML={{ __html: subhead }}
          />
        )}
      </>
    )}
    {variant === 'icon' && (
      <>
        {iconType === 'check' && (
          <span className="check-icon">
            <Icon id="FiCheck" size={12} color={colors.base.white} />
          </span>
        )}
        {iconType === 'dash' && (
          <span className="dash-icon">
            <Icon id="FiMinus" size={32} color={colors.gray[400]} />
          </span>
        )}
      </>
    )}
  </>
);

export default TableCell;
